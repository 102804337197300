import React from "react"
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Featured from "./components/featured/Featured";

const App = () => {
  return (
    <>
      <Header />
      <Home />
      <Featured />
    </>
  )
}

export default App;